// @import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";

body {
    font-family: 'Lato';
    /*min-height: 2000px;*/
}

.navbar-static-top {
    margin-bottom: 19px;
}

.fa-btn {
    margin-right: 6px;
}
